import { AjaxResponse } from '@af-common/Common/_actions/AjaxAction';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import Pre from '@af-common/Common/_containers/PrerequisiteContainer';
import { Country, Province } from '@af-common/UIData/_stores/UIDataStore';
import { IUserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, NativeSelect, TextField } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Dispatch } from 'redux';

export type Props = {
	opened: boolean
	onClose: Function
	address?: Immutable.Record<IUserAddressStore>
	id?: Number,
	onSubmit?: Function
	countries?: Immutable.Map<any, Country>
	callback?: (addresses: (IUserAddressStore & {id:number})[]) => void
};

type State = {
	title: string,
	name: string,
	address: string,
	address2: string,
	postal_code: string,
	city: string,
	province: string,
	id_country: string,
	phone: string,
	title_changed: boolean,
	name_changed: boolean,
	address_changed: boolean,
	postal_code_changed: boolean,
	city_changed: boolean,
	province_changed: boolean,
	phone_changed: boolean,
}

export default class AddressForm extends AwareComponent<Props, State> {
	state = {
		title: "",
		name: "",
		address: "",
		address2: "",
		postal_code: "",
		city: "",
		province: "",
		id_country: "",
		phone: "",
		title_changed: false,
		name_changed: false,
		address_changed: false,
		postal_code_changed: false,
		city_changed: false,
		province_changed: false,
		phone_changed: false,
	} as State

	componentDidMount() {
		if(this.props.address) {
			this.setState({
				title: this.props.address.get('title'),
				name: this.props.address.get('name'),
				address: this.props.address.get('address'),
				address2: this.props.address.get('address2'),
				postal_code: this.props.address.get('postal_code'),
				city: this.props.address.get('city'),
				province: this.props.address.get('province'),
				id_country: this.props.address.get('id_country'),
				phone: this.props.address.get('phone'),
				title_changed: true,
				name_changed: true,
				address_changed: true,
				postal_code_changed: true,
				city_changed: true,
				province_changed: true,
				phone_changed: true,
			})
		}
	}

	componentDidUpdate(_props:Props, _state:State) {
		if(this.propChanged(_props, 'countries')) {
			this.setState({'id_country': this.props.countries.keySeq().first()});
		}

		if(this.propChanged(_props, 'opened') && this.props.opened) {
			let fieldsync = {
				title_changed: false,
				name_changed: false,
				address_changed: false,
				postal_code_changed: false,
				city_changed: false,
				province_changed: false,
				phone_changed: false,
			}
			if(!this.props.address) this.setState({
				title: "",
				name: "",
				address: "",
				postal_code: "",
				city: "",
				province: "",
				phone: "",
				address2: "",
				...fieldsync
			});
			else this.setState({
				title: this.props.address.get('title'),
				name: this.props.address.get('name'),
				address: this.props.address.get('address'),
				address2: this.props.address.get('address2'),
				postal_code: this.props.address.get('postal_code'),
				city: this.props.address.get('city'),
				province: this.props.address.get('province'),
				id_country: this.props.address.get('id_country'),
				phone: this.props.address.get('phone'),
				...fieldsync
			});
		}
		
		if(this.stateChanged(_state, 'id_country')) {
			if(
				this.props.countries.get(this.state.id_country) && 
				this.props.countries.get(this.state.id_country).get('provinces').size && 
				!this.props.countries.get(this.state.id_country).get('provinces').find((value: any, key: number) => value.get('name') == this.state.province)
			) {
				this.setState({
					province: this.props.countries.get(this.state.id_country).get('provinces').valueSeq().first<Province>().get('name') as string
				});
			}
		}
	}

	private onSave(e:React.FormEvent) {
		e.preventDefault();
		
		this.setState({
			title_changed: true,
			name_changed: true,
			address_changed: true,
			postal_code_changed: true,
			city_changed: true,
			province_changed: true,
			phone_changed: true
		}, () => {
			if (this.validateForm()) {
				let formData = new FormData();
				if(this.props.id)
					formData.append("id", String(this.props.id));

				if(!this.props.address || this.props.address.get('name') != this.state.name)
					formData.append('name', this.state.name);

				if(!this.props.address || this.props.address.get('address') != this.state.address)
					formData.append('address', this.state.address);

				if(!this.props.address || this.props.address.get('address2') != this.state.address2)
					formData.append('address2', this.state.address2);

				if(!this.props.address || this.props.address.get('postal_code') != this.state.postal_code)
					formData.append('postal_code', this.state.postal_code);

				if(!this.props.address || this.props.address.get('city') != this.state.city)
					formData.append('city', this.state.city);

				if(!this.props.address || this.props.address.get('province') != this.state.province)
					formData.append('province', this.state.province);

				if(!this.props.address || this.props.address.get('id_country') != this.state.id_country)
					formData.append('id_country', this.state.id_country);

				if(!this.props.address || this.props.address.get('phone') != this.state.phone)
					formData.append('phone', this.state.phone);

				this.props.onSubmit(formData, (response: AjaxResponse, dispatch:Dispatch) => {
					if(response.data.addresses && this.props.callback){
						this.props.callback(response.data.addresses);
					}
					this.props.onClose();
				});
			}
		});
	}

	private validateForm():boolean {
		return this.validateField('name') &&
		this.validateField('address') &&
		this.validateField('postal_code') &&
		this.validateField('city') &&
		this.validateField('province') &&
		this.validateField('phone');
	};

	private validateField(field: string):boolean {
		let valid = false;
		switch(field) {
			case 'title':
				return !!this.state.title || !this.state.title_changed;
			break;

			case 'name':
				return !!this.state.name || !this.state.name_changed;
			break;

			case 'address':
				return !!this.state.address || !this.state.address_changed;
			break;

			case 'postal_code':
				if (this.state.id_country == '43') {
					var RegEx = new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$");
					valid = RegEx.test(this.state.postal_code);
				} else if (this.state.id_country == '240') {
					valid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.state.postal_code);
				} else {
					valid = true;
				}
				return valid || !this.state.postal_code_changed;
			break;

			case 'city':
				return !!this.state.city || !this.state.city_changed;
			break;

			case 'province':
				return !!this.state.province || !this.state.province_changed;
			break;

			case 'phone':
				return !!this.state.phone || !this.state.phone_changed;
			break;
		}
	}

	render = () => {
		let title = !this.validateField('title');
		let name = !this.validateField('name');
		let phone = !this.validateField('phone');
		let address = !this.validateField('address');
		let postal_code = !this.validateField('postal_code');
		let city = !this.validateField('city');
		let province = !this.validateField('province');
		let invalid = title || name || phone || address || postal_code || city || province;

		const ready = this.props.countries && this.props.countries.count();

		return <Pre name="countries" ready={!!ready} content={() => 
			<Dialog className="address-form" open={this.props.opened} onClose={() => this.props.onClose()}>
				<form onSubmit={this.onSave.bind(this)}>
					<DialogTitle>{this.props.id ? 'Edit' : 'Add'} Address</DialogTitle >
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item sm={7}>
								<TextField
									fullWidth
									label='Name'
									value={this.state.name}
									error={name}
									helperText={name ? "Cannot be empty" : ""}
									onChange={(e) => this.setState({
										name: e.currentTarget.value,
										name_changed: true
									})}
								/>
							</Grid>
							<Grid item sm={5}>
								<TextField
									fullWidth
									label='Phone'
									value={this.state.phone}
									error={phone}
									helperText={phone ? "Cannot be empty" : ""}
									onChange={(e) => this.setState({
										phone: e.currentTarget.value,
										phone_changed: true
									})}
								/>
							</Grid>
							<Grid item sm={12}>
								<TextField
									fullWidth
									label='Street Address'
									value={this.state.address}
									error={address}
									helperText={address ? "Cannot be empty" : ""}
									onChange={(e) => this.setState({
										address: e.currentTarget.value,
										address_changed: true
									})}
								/>
							</Grid>
							<Grid item sm={12}>
								<TextField
									fullWidth
									label='Street Address (2nd line)'
									value={this.state.address2}
									onChange={(e) => this.setState({
										address2: e.currentTarget.value
									})}
								/>
							</Grid>
							<Grid item sm={6}>
								<TextField
									fullWidth
									label='Postal Code'
									value={this.state.postal_code}
									error={postal_code}
									helperText={postal_code ? "Please enter a valid postal code" : ""}
									onChange={(e) => this.setState({
										postal_code: e.currentTarget.value,
										postal_code_changed: true
									})}
								/>
							</Grid>
							<Grid item sm={6}>
								<TextField
									fullWidth
									label='City'
									value={this.state.city}
									error={city}
									helperText={city ? "Cannot be empty" : ""}
									onChange={(e) => this.setState({
										city: e.currentTarget.value,
										city_changed: true
									})}
								/>
							</Grid>
							<Grid item sm={6}>
								<FormControl  style={{width:"100%"}}>
									<InputLabel variant="standard" htmlFor='address-from-country'>Country</InputLabel>
									<NativeSelect
										value={this.state.id_country}
										onChange={(e) => this.setState({
											id_country: e.currentTarget.value as string
										})}
										input={<Input id="address-from-country" name="country" />}
									>{this.props.countries.valueSeq().map((v, k) => {
										return <option key={v.get('id')} value={v.get('id')}>{v.get('name')}</option>
									})}</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								{ this.props.countries.get(this.state.id_country) && this.props.countries.get(this.state.id_country).get('provinces').size ?
									<FormControl style={{width:"100%"}}>
										<InputLabel variant="standard" htmlFor="address-from-province">Province</InputLabel>
										<NativeSelect
											value={this.state.province}
											onChange={(e) => this.setState({
												province: e.currentTarget.value as string
											})}
											inputProps={{
												name: 'province',
												id: 'address-from-province'
											}}
										>{this.props.countries.get(this.state.id_country).get('provinces').valueSeq().map((v, k) => {
											return <option key={v.get('name')} value={v.get('name')}>{v.get('name')}</option>
										})}</NativeSelect>
									</FormControl>
									:
									<TextField
										fullWidth
										label='Province'
										error={province}
										helperText={province ? "Cannot be empty" : ""}
										value={this.state.province}
										onChange={(e) => this.setState({
											province: e.currentTarget.value,
											province_changed: true
										})}
									/>
								}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions style={{ justifyContent: "space-between" }}>
						<Button color="secondary" onClick={() => this.props.onClose()}>Cancel</Button>
						<Button color="secondary" className="secondary-primary" disabled={invalid} type="submit">Save</Button>
					</DialogActions>
				</form>
			</Dialog>
		} />
	}
}
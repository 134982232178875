import AwareComponent from '@af-common/Common/_components/AwareComponent';
import Pre from '@af-common/Common/_containers/PrerequisiteContainer';
import { Fabric } from '@af-common/UIData/_data/Fabrics';
import { BaseItem, RegularItem } from '@af-common/UserData/_stores/UserCartStore';
import { Hidden, IconButton, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Dinero from 'dinero.js';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Trans } from 'react-i18next';

export type Props = {
	id: string
	item?: BaseItem
	fabrics?: Immutable.OrderedMap<number, Fabric>
	editable?: boolean
	updateCartItem?: (fd:FormData)=>void
	deleteCartItem?: (fd:FormData)=>void
	lang?: string
	currency?: string
}

type State = {
	[key: string]:any
	quantity: number
}

const T = ({children, ...otherProps}:any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default class CartItem extends AwareComponent<Props, State> {
	// Gotta retype or we lose the string access
	state = { quantity: this.props.item.get('quantity') } as {
		[key: string]:any
		quantity: number
	}

	private onQuantityChange = (e:React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		let value = Number(e.target.value);
		value = Math.min(value, 9999);
		value = Math.max(value, 1);
		
		this.setState({quantity: value});
	}

	private onCartItemDelete = (e:React.MouseEvent) => {
		e.preventDefault();
		const fd = new FormData();
		fd.append('id', this.props.id);
		this.props.deleteCartItem(fd);
	}

	componentDidUpdate(_prevProps:Props, _prevState:State) {
		const changes = this.whichStatesChanged(_prevState);
		if(changes.length) {
			const fd = new FormData();
			fd.append('id', this.props.id);

			for(let i in changes) {
				if(!changes.hasOwnProperty(i)) continue;
				const key = changes[i];
				fd.append(key, this.state[key]);
			}

			this.props.updateCartItem(fd);
		}
	}

	render = () => {
		// TODO : This section will need changes when we add other item types
		let fabric: any, size: any, src: any;

		if(this.props.item.get('type') == 'regular' &&  this.props.fabrics.count()){
			fabric = this.props.fabrics.get((this.props.item as RegularItem).get('fabric').get('id_fabric'));

			if(fabric) {
				size = fabric.getIn(['sizes', this.props.item.getIn(['fabric', 'id_size'])]);
				src = '/user/patterns/pattern/'+this.props.item.getIn(['fabric', 'id_design']);
			}
		}

		return <Pre name="fabrics" content={() => 
			<TableRow className={"cart-item type-"+this.props.item.get('type')}>
				<TableCell>
					{ this.props.item.get('type') == 'regular' && 
						<img className="cart-item-thumb" src={'/user/patterns/pattern/'+this.props.item.getIn(['fabric','id_design'])}/>
					}
					{ this.props.item.get('type') == 'sample-pack' && 
						<img className="cart-item-thumb" src={'/static/img/pages/order-sample-pack/sample-pack.png'}/>
					}
					{ this.props.item.get('type') == 'sample-pack-natural' && 
						<img className="cart-item-thumb" src={'/static/img/pages/order-sample-pack/sample-pack-natural.jpg'}/>
					}
					{ this.props.item.get('type') == 'sample-pack-synthetic' && 
						<img className="cart-item-thumb" src={'/static/img/pages/order-sample-pack/sample-pack-synthetic.jpg'}/>
					}
					{ this.props.item.get('type') == 'sample-pack-natural-synthetic' && 
						<img className="cart-item-thumb" src={'/static/img/pages/order-sample-pack/sample-pack-natural-synthetic.jpg'}/>
					}
					{ this.props.item.get('type') == 'sample-pack-vegan-leather' && 
						<img className="cart-item-thumb" src={'/static/img/pages/order-sample-pack/bubble-sample/sample-veganleather.png'}/>
					}
				</TableCell>

				<Hidden smDown>
					{ this.props.item.get('type') == 'regular' ? <>
						<TableCell>
							<Typography variant="body2">{size && size.get('name')}</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="body2">{fabric && fabric.get('name')}</Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'pattern-design' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>patterndesigns.com fee</T></Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'sample-pack' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>Sample pack</T></Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'sample-pack-natural' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>Natural Sample pack</T></Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'sample-pack-synthetic' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>Synthetic Sample pack</T></Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'sample-pack-natural-synthetic' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>Natural &amp; Synthetic Sample packs</T></Typography>
						</TableCell>
					</> : this.props.item.get('type') == 'sample-pack-vegan-leather' ? <>
						<TableCell colSpan={2}>
							<Typography variant="body2"><T>Vegan Leather Sample pack</T></Typography>
						</TableCell>
					</> : <>
						<TableCell colSpan={2}></TableCell>
					</> }
				</Hidden>

				<TableCell>
					<Hidden mdUp>
						<div className="item-info">
							{ this.props.item.get('type') == 'regular' ? <>
								<Typography variant="body2">{size && size.get('name')}</Typography>
								<Typography variant="body2">{fabric && fabric.get('name')}</Typography>
							</> : this.props.item.get('type') == 'pattern-design' ? <>
								<Typography variant="body2"><T>patterndesigns.com fee</T></Typography>
							</> : this.props.item.get('type') == 'sample-pack' ? <>
								<Typography variant="body2"><T>Sample pack</T></Typography>
							</> : this.props.item.get('type') == 'sample-pack-natural' ? <>
								<Typography variant="body2"><T>Natural Sample pack</T></Typography>
							</> : this.props.item.get('type') == 'sample-pack-synthetic' ? <>
								<Typography variant="body2"><T>Synthetic Sample pack</T></Typography>
							</> : this.props.item.get('type') == 'sample-pack-natural-synthetic' ? <>
								<Typography variant="body2"><T>Natural &amp; Synthetic Sample packs</T></Typography>
							</> : this.props.item.get('type') == 'sample-pack-vegan-leather' ? <>
								<Typography variant="body2"><T>Vegan Leather Sample pack</T></Typography>
							</> : <></> }
						</div>
					</Hidden>

					{ this.props.editable && !this.props.item.get('fixedQuantity') ? <div className="quantity-wrap">
						<TextField
							variant="outlined"
							type="number"
							value={this.state.quantity}
							onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.onQuantityChange(e)}
							size="small"
							inputProps={{
								min: 1,
								max: 9999
							}}
						/> 
						<IconButton onClick={(e:React.MouseEvent) => this.onCartItemDelete(e)}><i className="material-icons">delete</i></IconButton>
					</div> : <Typography variant="body2">{this.props.item.get('quantity')}</Typography>}
				</TableCell>
				<TableCell>
					<Typography variant="body2" className="cart-page--price">×&nbsp;{Dinero(this.props.item.get('price').toObject()).toFormat()}</Typography>
				</TableCell>
			</TableRow>
		} />
	}
}

import * as React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography, Select, SelectChangeEvent, FormControl, InputLabel, MenuItem, ListItemText } from '@mui/material';
import Dinero from 'dinero.js';
import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import { Trans } from 'react-i18next';

export type Props = {
	cart: UserCartStore
	updateShippingType?: Function
	noEdit?: boolean
	lang?: string
	currency?: string
}

const T = ({ children, ...otherProps }: any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default class CartSummary extends React.Component<Props>{
	private onShippingTypeChange = (e: SelectChangeEvent<number>) => {
		const value = e.target.value;

		if (value == this.props.cart.get('id_shipping_type')) return;

		const fd = new FormData();
		fd.append('type', '' + value);
		this.props.updateShippingType(fd);
	}

	render = () => {
		return <div className="cart-summary-wrapper">
			<div className="cart-summary-item">
				<h6><T>Subtotal</T></h6>
				<span>{Dinero(this.props.cart.get('subtotal').toObject()).toFormat()}</span>
			</div>
			<div className="cart-summary-item">
				<h6 style={!this.props.cart.get('pickup') && this.props.cart.get('id_shipping_address') ? { border: 0 } : {}}><T>Shipping</T></h6>
				<span style={!this.props.cart.get('pickup') && this.props.cart.get('id_shipping_address') ? { border: 0 } : {}}>{Dinero(this.props.cart.get('shipping').toObject()).toFormat()}</span>
				{!this.props.noEdit && !this.props.cart.get('pickup') && this.props.cart.get('id_shipping_address') &&
					<div className="shipping-options">
						<FormControl>
							<Select
								labelId="shipping-type-label"
								id="shipping-type"
								value={this.props.cart.get('id_shipping_type')}
								onChange={this.onShippingTypeChange}
							>
								{this.props.cart.get('availableShippingTypes').entrySeq().map(([id, type]) => {
									return <MenuItem key={String(id)} value={+type.get('id')}>
										<ListItemText primary={type.get('name')} />
									</MenuItem>
								})}
							</Select>
						</FormControl>
					</div>
				}
			</div>

			{ /* Dinero(this.props.cart.get('discount').toObject()).getAmount() > 0 ? <>
				<div className="cart-summary-item" style={{
					background: '#151744',
					border: '0',
					padding: '15px',
					margin: '15px -15px',
					borderRadius: '20px',
				}}>
					<h6 style={{
						width: '100%',
						fontSize: '18px',
						color: '#fff',
						marginBottom: '5px',
					}}>BLACK FRIDAY 2024</h6>
					<h6 style={{color: '#fff'}}><T>Discount</T></h6>
					<span style={{color: '#fff'}}>{Dinero(this.props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
				</div>
			</> : null */ }

			{ Dinero(this.props.cart.get('discount').toObject()).getAmount() > 0 ? <div className="cart-summary-item">
				<h6><T>Discount</T></h6>
				<span>{Dinero(this.props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
			</div> : null }

			<div className="cart-summary-item">
				<h6><T>Taxes</T></h6>
				<span>{Dinero(this.props.cart.get('taxes').toObject()).toFormat()}</span>
			</div>
			<div className="cart-summary-item">
				<h6><T>Total</T></h6>
				<span>{Dinero(this.props.cart.get('total').toObject()).toFormat()}</span>
			</div>
		</div>
	}
}
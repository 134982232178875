import * as React from 'react';
import * as Immutable from 'immutable';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Paper, Typography, TextField, Button } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import Fabrics, { Fabric, FabricCategory as Category } from '@af-common/UIData/_data/Fabrics';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import { UpdateCartItem } from '@af-common/UserData/_actions/UserCartActions';
import { useNavigate } from 'react-router-dom';

export default function OrderSamplePack() {
	const [tR] = useTranslation('routes')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [t] = useTranslation('pages/order-sample-pack')
	const cart_item_loading = useSelector((state:WebsiteStore) => state.get('UIData').get('loaders').get('cart_item'))
	const currency = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('currency'))

	const onAddToCart = (e:React.FormEvent, type: 'sample-pack-natural'|'sample-pack-synthetic'|'sample-pack-natural-synthetic'|'sample-pack-vegan-leather') => {
		if(cart_item_loading) { return false; }

		e.preventDefault();

		let fd = new FormData();
		
		fd.append("quantity", String(1));
		fd.append("cart_item_type", type);

		dispatch(UpdateCartItem(fd))
		navigate(tR('/cart'));


		let price = 0;

		switch (type) {
			case 'sample-pack-natural': 
				price = currency === 'CAD' ? 10 : 8;
				break;
			case 'sample-pack-synthetic': 
				price = currency === 'CAD' ? 10 : 8;
				break;
			case 'sample-pack-natural-synthetic': 
				price = currency === 'CAD' ? 16 : 13;
				break;
			case 'sample-pack-vegan-leather': 
				price = currency === 'CAD' ? 5 : 4;
				break;
		}

		window.fbq('track', 'AddToCart', {
			content_type: 'product',
			content_ids: [type],
			content_name: type,
			value: price,
			currency: currency,
			contents: [
				{
					id: type,
					quantity: 1,
				}
			]
		});

		window.gtag('event', 'add_to_cart', {
			currency: currency,
			value: price,
			items: [
				{
					item_id: type,
					item_name: type,
					price: price,
					quantity: 1,
				}
			]
		})
	}

	return <>
		<Helmet>
			<title>{t('Order your fabric sample pack')}</title>
			<meta name="description" content={t('Order your fabric sample pack and see for yourself the quality and variety of our custom printed fabrics that we offer!')}/>
		</Helmet>

		<Header light={true} />

		<div id="sample-pack-page--header">
			<div className="inner-header">
				<Container className="header-cta" maxWidth={"lg"}>
					
					<Container>
						<Grid container>
							<Grid item xs={12} sm={6} md={5} lg={5}>
								<div className="banner-img">
									<img src="static/img/pages/order-sample-pack/bubble-sample/sample-natural.png" alt={t('Picture of the Art Fabrics natural sample pack')}/>
								</div>
							</Grid>
							<Grid item container xs={12} sm={6} md={7} lg={7} alignItems={"center"}>
								<div className="floating-text floating-text-right header-text">
									<h2>{t('Natural sample pack')}</h2>
									<p>{t('12 fabric options. 6" x 6" swatches')}</p>
										<p className="price-text">${ currency === 'CAD' ? '10.00':'8.00' }</p>
									<Button color="primary" onClick={(e) => onAddToCart(e, 'sample-pack-natural')} disabled={cart_item_loading} >{t('Order now')}</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Container style={{
						marginTop: '40px'
					}}>
						<Grid container>
							<Grid item xs={12} sm={6} md={5} lg={5}>
								<div className="banner-img">
									<img src="static/img/pages/order-sample-pack/bubble-sample/sample-synthetic.png" alt={t('Picture of the Art Fabrics synthetic sample pack')}/>
								</div>
							</Grid>
							<Grid item container xs={12} sm={6} md={7} lg={7} alignItems={"center"}>
								<div className="floating-text floating-text-right header-text">
									<h2>{t('Synthetic sample pack')}</h2>
									<p>{t('15 fabric options. 6" x 6" swatches')}</p>
										<p className="price-text">${ currency === 'CAD' ? '10.00':'8.00' }</p>
									<Button color="primary" onClick={(e) => onAddToCart(e, 'sample-pack-synthetic')} disabled={cart_item_loading} >{t('Order now')}</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Container style={{
						marginTop: '40px'
					}}>
						<Grid container>
							<Grid item xs={12} sm={6} md={5} lg={5}>
								<div className="banner-img">
									<img src="static/img/pages/order-sample-pack/bubble-sample/sample-naturalsynthetic.png" alt={t('Picture of the Art Fabrics natural & synthetic sample pack')}/>
								</div>
							</Grid>
							<Grid item container xs={12} sm={6} md={7} lg={7} alignItems={"center"}>
								<div className="floating-text floating-text-right header-text">
									<h2>{t('Synthetic and natural sample packs')}</h2>
									<p>{t('27 fabric options. 6" x 6" swatches')}</p>
										<p className="price-text">${ currency === 'CAD' ? '16.00':'13.00' }</p>
									<Button color="primary" onClick={(e) => onAddToCart(e, 'sample-pack-natural-synthetic')} disabled={cart_item_loading} >{t('Order now')}</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Container style={{
						marginTop: '40px'
					}}>
						<Grid container>
							<Grid item xs={12} sm={6} md={5} lg={5}>
								<div className="banner-img">
									<img src="static/img/pages/order-sample-pack/bubble-sample/sample-veganleather.png" alt={t('Picture of the Art Fabrics vegan leathers sample pack')}/>
								</div>
							</Grid>
							<Grid item container xs={12} sm={6} md={7} lg={7} alignItems={"center"}>
								<div className="floating-text floating-text-right header-text">
									<h2>{t('Vegan Leather sample pack')}</h2>
									<p>{t('3 fabric options. 6" x 6" swatches')}</p>
										<p className="price-text">${ currency === 'CAD' ? '5.00':'4.00' }</p>
									<Button color="primary" onClick={(e) => onAddToCart(e, 'sample-pack-vegan-leather')} disabled={cart_item_loading} >{t('Order now')}</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
				</Container>
			</div>
		</div>
		<Footer/>
	</>
}